<template>
  <!-- Add new city modal -->
  <b-modal
    id="modal-new-city"
    :visible="isEditCityModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.save')"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('city.form.editHeader')"
    no-close-on-backdrop
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        label="City"
        label-for="city"
        :invalid-feedback="$t('city.form.nameInvalid')"
      >
        <b-form-input
          id="city"
          v-model="cityName"
          type="text"
          :state="nameState"
          :placeholder="$t('city.form.namePlaceholder')"
          required
        />
      </b-form-group>
      <b-form-group
        :state="provinceState"
        :label="$t('province.form.name')"
        label-for="province"
        :invalid-feedback="$t('province.form.nameInvalid')"
      >
        <v-select
          id="province"
          v-model="selectedProvince"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="provinceOptions"
          :filterable="false"
          :placeholder="$t('province.form.namePlaceholder')"
          :reduce="province => province.value"
        >
          <template #list-footer>
            <li
              v-show="hasNextPage"
              ref="load"
              class="text-center text-primary"
            >
              {{ $t('message.moreOption') }}
            </li>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Status" label-for="status">
        <div>
          <b-form-checkbox
            :checked="cityState"
            class="custom-control-success"
            name="check-button"
            switch
            @change="updateState"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </b-form-group>
    </form>
    <template v-slot:modal-ok>
      <div class="d-flex align-center">
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        {{ $t('common.save') }}
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BModal,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: ['isEditCityModalShow', 'data'],
    event: ['update:is-edit-city-modal-show', 'update:data'],
  },
  props: {
    isEditCityModalShow: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Function,
      required: true,
    },
    nextPage: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const cityName = ref('')
    const selectedProvince = ref(null)
    const nameState = ref(null)
    const cityState = ref(false)
    const provinceState = ref(null)
    const form = ref(null)
    const provinceOptions = ref([])
    const page = ref(1)
    const isLoading = ref(false)
    const toast = useToast()

    const hasNextPage = computed(() => props.nextPage !== null)

    props.items({ perPage: 999999, page: page.value }, data => {
      data.forEach(item => {
        provinceOptions.value.push({
          value: item.province_id,
          label: item.province_name,
        })
      })
    })

    watch(
      () => props.isEditCityModalShow,
      val => {
        if (val) {
          cityName.value = props.data.name
          selectedProvince.value = props.data.province_id
          cityState.value = props.data.is_active
        }
      },
    )

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      provinceState.value = selectedProvince.value !== null
      // console.log(nameState.value, provinceState.value)
      return nameState.value && provinceState.value
    }

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        // eslint-disable-next-line no-useless-return
        console.log('invalid', checkFormValidity())
        return
      }
      isLoading.value = true
      // EDIT ETHNIC NAME //
      // eslint-disable-next-line no-underscore-dangle
      store
        .dispatch('master-city/editCity', {
          province_id: selectedProvince.value,
          city_name: cityName.value,
          // eslint-disable-next-line no-underscore-dangle
          id: props.data._id,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-city-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success editing a city',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error editing a city',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // UPDATE STATUS ACTIVE INACTIVE
    const updateState = () => {
      // eslint-disable-next-line no-underscore-dangle
      store
        .dispatch('master-city/updateCityState', {
          // eslint-disable-next-line no-underscore-dangle
          id: props.data._id,
          status: !cityState.value,
        })
        .then(() => {
          emit('refetch-data')
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating state',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (
        props.data.name !== cityName.value ||
        props.data.province_id !== selectedProvince.value
      ) {
        handleSubmit()
      } else {
        emit('update:is-edit-city-modal-show', false)
      }
    }

    const resetModal = () => {
      cityName.value = ''
      selectedProvince.value = null
      nameState.value = null
      provinceState.value = null
      emit('update:is-edit-city-modal-show', false)
      emit('update:data', null)
    }

    return {
      cityName,
      nameState,
      selectedProvince,
      provinceOptions,
      form,
      handleSubmit,
      hasNextPage,
      cityState,
      provinceState,
      handleOk,
      resetModal,
      updateState,
      isLoading,
    }
  },
}
</script>
