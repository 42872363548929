import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/city', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProvinces(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/province', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/city/create', { ...payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCity(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/city/update/${payload.id}`, { province_id: payload.province_id, city_name: payload.city_name })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCityState(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/city/setstatus', { ...payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/city/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
