<template>
  <!-- Add new city modal -->
  <b-modal
    id="modal-new-city"
    :visible="isAddNewCityModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.create')"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('city.form.header')"
    no-close-on-backdrop
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="cityState"
        :label="$t('city.form.name')"
        label-for="city"
        :invalid-feedback="$t('city.form.nameInvalid')"
      >
        <b-form-input
          id="city"
          v-model="cityName"
          type="text"
          :state="cityState"
          :placeholder="$t('city.form.namePlaceholder')"
          required
        />
      </b-form-group>
      <b-form-group
        :state="provinceState"
        :label="$t('province.form.name')"
        label-for="province"
        :invalid-feedback="$t('province.form.nameInvalid')"
      >
        <v-select
          id="province"
          v-model="selectedProvince"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="provinceOptions"
          :filterable="false"
          :placeholder="$t('province.form.namePlaceholder')"
          :reduce="province => province.value"
          @open="onOpen"
          @close="onClose"
        >
          <template #list-footer>
            <li
              v-show="hasNextPage"
              ref="load"
              class="text-center text-primary"
            >
              {{ $t('message.moreOption') }}
            </li>
          </template>
        </v-select>
      </b-form-group>
    </form>
    <template v-slot:modal-ok>
      <div class="d-flex align-center">
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
        {{ $t('common.create') }}
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BModal, VBModal } from 'bootstrap-vue'
import { onMounted, ref, nextTick, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-city-modal-show',
  },
  props: {
    items: {
      type: Function,
      required: true,
    },
    nextPage: {
      type: Number,
      default: null,
    },
    isAddNewCityModalShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const cityName = ref('')
    const selectedProvince = ref(null)
    const cityState = ref(null)
    const provinceState = ref(null)
    const form = ref(null)
    const observer = ref(null)
    const provinceOptions = ref([])
    const page = ref(1)
    const load = ref(null)
    const isLoading = ref(false)

    const toast = useToast()

    const hasNextPage = computed(() => props.nextPage !== null)

    const checkFormValidity = () => {
      cityState.value = form.value.checkValidity()
      provinceState.value = selectedProvince.value !== null
      return cityState.value && provinceState.value
    }

    props.items({ perPage: 10, page: page.value }, data => {
      data.forEach(item => {
        provinceOptions.value.push({
          value: item.province_id,
          label: item.province_name,
        })
      })
    })

    const onOpen = async () => {
      await nextTick()
      observer.value.observe(load.value)
    }

    const onClose = () => {
      observer.value.disconnect()
    }

    const infiniteScroll = async ([{ isIntersecting, target }]) => {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        page.value += 1

        await props.items({ perPage: 10, page: page.value }, data => {
          provinceOptions.value = provinceOptions.value.concat(
            data.map(item => ({
              value: item.province_id,
              label: item.province_name,
            })),
          )
        })

        await nextTick()
        ul.scrollTop = scrollTop
      }
    }

    onMounted(() => {
      observer.value = new IntersectionObserver(infiniteScroll)
    })

    const handleSubmit = () => {
      if (!checkFormValidity()) {
        console.log('invalid', checkFormValidity())
        // eslint-disable-next-line no-useless-return
        return
      }

      isLoading.value = true

      store
        .dispatch('master-city/addCity', {
          province_id: selectedProvince.value,
          city_name: cityName.value,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-city-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success creating new city',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new city',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const resetModal = () => {
      cityName.value = ''
      selectedProvince.value = null
      cityState.value = null
      emit('update:is-add-new-city-modal-show', false)
    }

    return {
      cityName,
      selectedProvince,
      provinceOptions,
      form,
      load,
      onOpen,
      onClose,
      handleSubmit,
      hasNextPage,
      cityState,
      provinceState,
      handleOk,
      resetModal,
      isLoading,
    }
  },
}
</script>
